<template>
  <section>
    <v-sheet
      color="white"
      elevation="4"
      outlined
      rounded
      width="352px"
      class="ma-5 pt-7 pl-9 pr-9 rounded-lg welcome-page"
    >
      <div class="text-center title">
        <p class="title">Shipper</p>
      </div>
      <div class="mb-6">
        <span class="headline-6">
          Masuk
        </span>
      </div>
      <v-form ref="loginForm" @submit.prevent="login">
        <v-text-field
          v-model="email"
          ref="email"
          :label="$_strings.welcomePage.EMAIL"
          clearable
          dense
          :readonly="isReadonly"
          :rules="rules.email"
          @focus="clearRulesEmail"
          @blur="blurRulesEmail"
          class="body-2"
          :hint="emailHint"
          persistent-hint
        >
        </v-text-field>
        <v-text-field
          ref="password"
          v-model="password"
          :label="$_strings.welcomePage.PASSWORD"
          class="body-2 mt-4"
          dense
          :type="passwordShow ? 'text' : 'password'"
          :rules="rules.password"
          :append-icon="passwordShow ? 'mdi-eye' : 'mdi-eye-off'"
          @click:append="passwordShow = !passwordShow"
        ></v-text-field>
        <router-link class="body-2" to="forgot-password">{{$_strings.welcomePage.FORGOT_PASSWORD}}</router-link>
        <v-btn
          rounded
          large
          class="background-denim white--text font-weight-bold body-2 w-100 mt-8"
          type="submit"
        >
          {{$_strings.welcomePage.LOGIN}}
        </v-btn>
      </v-form>
      <div class="d-flex ma-0 mt-6 justify-center body-2 grey--text">
        <p class="text-scorpion" >{{$_strings.welcomePage.CREATE_ACCOUNT}} <router-link to="registration"> {{$_strings.welcomePage.REGISTER}}</router-link></p>
      </div>
    </v-sheet>
    <welcome-footer/>
  </section>
</template>

<script>
import WelcomeFooter from './WelcomeFooter.vue';

export default {
  name: 'Login-form',
  components: {
    WelcomeFooter,
  },
  data() {
    return {
      yearNow: new Date().getFullYear(),
      email: '',
      password: '',
      passwordShow: false,
      emailHint: '',
      rulesEmail: [
        (value) => {
          if (!value) {
            return this.$_strings.messages.validation.REQUIRED(this.$_strings.companyInformation.EMAIL);
          }
          return this.isEmailValid(value) || this.$_strings.messages.validation.EMAIL;
        },
      ],
      rules: {
        email: [],
        password: [
          (v) => {
            if (v) {
              return !!v;
            }
            return this.$_strings.messages.validation.REQUIRED('Password');
          },
        ],
      },
      formHasErrors: false,
      isReadonly: true,
    };
  },
  computed: {
    loginForm() {
      return {
        email: this.email,
        password: this.password,
      };
    },
  },
  methods: {
    navigateToPageMain() {
      if (this.$store.getters['auth/isLoggedIn']) {
        const menu = JSON.parse(this.$store.getters['auth/menu']);
        if (menu[0].child.length > 0) {
          this.$router.replace(menu[0].child[0].url);
          return;
        }
        this.$router.replace(menu[0].url);
      }
    },
    async login() {
      this.email = this.email && this.email.trim();
      this.rules.email = this.rulesEmail;
      this.$nextTick(async () => {
        const valid = this.$refs.loginForm.validate();
        if (valid) {
          try {
            this.$root.$loading.show();
            await this.$store.dispatch('auth/login', this.loginForm);
          } finally {
            this.navigateToPageMain();
            this.$root.$loading.hide();
          }
        }
      });
    },
    clearRulesEmail() {
      this.isReadonly = false;
      this.emailHint = this.$_strings.welcomePage.EMAIL_HINT;
      this.rules.email = [];
    },
    blurRulesEmail() {
      this.email = this.email && this.email.trim();
      this.emailHint = '';
      this.rules.email = this.rulesEmail;
    },
  },

};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
  .title, .title sub{
    color: $--color-doveGray;
    font-size: 16px !important;
  }
  .v-footer {
    background: none;
    position: absolute;
    right: 0;
    bottom: 0;
}
</style>
